import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { get as getOrder } from '../../helper/services'

import Layout from '../../components/layout'
import { Title, Main, Content } from '../../components/styles/pages'

import qs from 'qs'

const MainContent = styled(Content)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`

const CheckoutSuccess = props => {
  const [order, setOrder] = useState(null)
  const [error, setError] = useState(false)
  useEffect(() => {
    const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })

    getOrder(`${process.env.GATSBY_ORDER_API}/${params.session_id}`)
      .then(order => {
        setOrder(order)
      })
      .catch(e => {
        setError(true)
      })

    // setOrderId(params.session_id)
  }, [])
  const { pageResources } = props
  return (
    <>
      <Layout pageResources={pageResources}>
        <Main>
          <Title>Grazie</Title>
          <MainContent>
            <h2>Ordine compleato</h2>
            {order === null && <p>Stiamo finalizzando il tuo ordine...</p>}
            {order && (
              <>
                <p>
                  Hai completato l'ordine con successo.
                  <br />
                  Il tuo numero d'ordine è <strong>{order.order_id}</strong>
                </p>

                <p>
                  Riceverai un email contente i dati del tuo ordine e le
                  informazioni relative alla spedizione.
                  <br />
                  Per qualsiasi informazione contatta{' '}
                  <a href="mailto:info@simonefazio.com">info@simonefazio.com</a>
                </p>
              </>
            )}
          </MainContent>
        </Main>
      </Layout>
    </>
  )
}

export default CheckoutSuccess
